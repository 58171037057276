.auth-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}
.auth-left,
.auth-right {
  /* min-width: 50vw; */
  padding: 0 5vw;
}

.auth-left img {
  width: 100%;
  height: auto;
}

.portal {
  border: 2px solid #353542;
  padding: 15px 30px;
  border-radius: 10px;
  /* width: 80%; */
  margin: auto;
}
.Auth-right-signup {
  margin-top: 40px;
}

.blog-input .ant-input,
.blog-input .ant-input-password {
  background-color: #1e1e1e;
  border-color: #353542;
}

.blog-input .ant-input:focus-within,
.blog-input .ant-input-password:focus-within,
.blog-input .ant-input-status-error {
  background-color: #1e1e1e !important;
  border-color: #353542;
}

.blog-input .ant-input,
.blog-input .ant-input-password {
  color: white;
}

.blog-input .ant-input::placeholder,
.blog-input .ant-input-password::placeholder {
  color: #a3a1a1;
}

.social-buttons {
  margin-top: -10px !important;
  display: flex;
  width: 90px;
  margin: auto;
  align-items: center;
  justify-content: space-between;
}

.social-buttons .social-button {
  width: 35px;
  background-color: transparent;
}

.social-buttons .social-button:hover,
.social-buttons .social-button:active {
  background-color: transparent !important;
}

.portal-head span {
  color: white;
}
.portal-head,
.portal-submit,
.portal-link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.portal-link,
.portal-link:hover {
  color: white;
}
.portal-head {
  font-size: 25px;
  font-weight: 900;
  padding-bottom: 10px;
  margin: 10px 0;
  border-bottom: 1px solid #353542;
}
.portal-head img {
  height: 30px;
  margin-right: 10px;
}
.portal-livecheck {
  margin-top: 6px;
}
.portal-livecheck-tick {
  color: green;
}
.portal-livecheck-x {
  color: tomato;
}
.portal-livecheck-load {
  color: darkgray;
}
.portal-err {
  color: tomato;
  text-align: center;
}
.portal-msg {
  color: dodgerblue;
  text-align: center;
}
.portal-notif {
  border: 2px solid #ebebeb;
  background-color: whitesmoke;
  padding: 15px 30px;
  border-radius: 10px;
  width: 80%;
  margin: 10px auto;
}

.portal-notif li {
  display: flex;
}
.portal-notif span:nth-child(1) {
  font-weight: 900;
  min-width: 80px;
  color: gray;
}
.portal-notif span:nth-child(2) {
  font-family: "Montserrat", sans-serif;
}
.portal-notif h3 {
  font-weight: 900;
  font-size: 20px;
  color: rgb(80, 80, 80);
}

@media only screen and (max-width: 600px) {
  .auth-layout {
    flex-direction: column-reverse;
    height: fit-content;
    align-items: center;
  }

  .auth-left {
    display: none;
  }
  .auth-left,
  .auth-right {
    min-height: 50vw;
    padding: 5vh 0;
  }

  .portal {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .portal .ant-form {
    width: 280px;
  }

  .social-buttons {
    width: auto;
  }
  .portal,
  .portal-notif {
    width: 300px;
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 350px) {
  .portal {
    width: 300px;
    padding: 10px 5px;
  }
}

.blog-app {
  .ant-btn {
    font-family: "Montserrat", sans-serif;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 900;
    min-width: 80px;
  }
  .ant-btn-lg {
    min-width: 100px;
  }
  .ant-btn-sm {
    min-width: 70px;
    font-size: 13px;
  }
  .ant-btn-primary {
    background-color: dodgerblue;
    border-color: dodgerblue;
  }
  .ant-input,
  .ant-input-affix-wrapper,
  .ant-input-group-addon,
  .ant-dropdown-menu,
  .ant-popover-inner,
  .ant-modal-content {
    border-radius: 6px;
  }
  .ant-modal-confirm .ant-modal-body {
    padding: 25px 25px;
  }
}

@media only screen and (max-width: 600px) {
  .ant-btn {
    min-width: 80px;
  }
}

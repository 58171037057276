.search {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3vh;
  font-family: "Ubuntu", sans-serif;
}
.search-input {
  width: 50%;
  height: 5vh;
  border-radius: 20px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ebebeb;
}
.search-input input {
  width: 100%;
  height: 100%;
  margin: 0 10px;
  border: none;
  outline: none;
  font-size: 15px;
}
.search-input span {
  cursor: pointer;
}

.search-msg {
  margin: 20px 0;
  min-height: 30px;
  color: darkgray;
}

.search-output {
  width: 50%;
}
.search-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  width: 80%;
  margin: auto;
  border-bottom: 2px solid #ebebeb;
}
.search-card img {
  width: 50px;
  height: 50px;
}
.search-card-left {
  display: flex;
}
.search-card-dtl {
  margin-left: 10px;
}
.search-card-dtl h4 {
  font-size: 18px;
  font-weight: 900;
  margin: 0;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-card-dtl p {
  color: gray;
  margin: 0;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-card .ant-btn {
  border-radius: 5px;
}

@media only screen and (max-width: 600px) {
  .search-input,
  .search-output {
    width: 90%;
  }
  .search-card {
    width: 100%;
    padding: 15px 15px;
  }

  .search-card-dtl h4 {
    font-size: 15px;
    max-width: 140px;
  }
  .search-card-dtl p {
    font-size: 13px;
    max-width: 140px;
  }
}

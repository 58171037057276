.publicReadBlog-container {
  background-color: white;
  min-height: 100vh;
  .blogs {
    padding-top: 5vh;
    overflow: scroll;
    .blog {
      font-family: "Montserrat", sans-serif;
      height: fit-content;
      width: 80vw;
      margin: 15px auto;
      padding: 15px 20px 5px 20px;
      .blog-title {
        padding: 15px 10px;
      }
      .Blog-Head {
        .Blog-Head-left {
          img {
            height: 60px;
            width: 60px;
          }
          .blog-details {
            h3 {
              margin-bottom: 10px;
            }
            .published-date {
              margin-left: 0;
            }
          }
        }
      }

      .blog-Body {
        border-top: 1px solid whitesmoke;
        margin: 10px 0;
        padding: 15px 10px;
        font-size: 14px;
        text-align: justify;
        white-space: pre-line;
        overflow: hidden;
        p {
          color: black !important;
          span {
            font-family: "Montserrat", sans-serif !important;
          }
        }
      }
    }
  }
}

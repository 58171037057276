.blog-portal-wrapper {
  background-color: white;
}

.blog-portal {
  width: 90vw;
  margin: auto;
  background-color: white;
}

.blog-portal .ant-form {
  width: 100%;
}
.blog-portal-nav {
  width: 200px;
  display: flex;
}

.blog-portal-nav .ant-btn {
  padding: 10px !important;
}
.createBlog-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.createBlog-footer a {
  color: #1890ff;
  margin-bottom: 1rem;
}
.blog-portal-nav .archive-btn {
  color: black;
  border: 1px solid grey;
}
.blog-portal-head {
  text-align: center;
  font-size: 25px;
  color: tomato;
  font-weight: 900;
  margin: 30px 0;
}
.blog-portal footer {
  text-align: center;
}
.blog-portal-nav > * {
  margin: 20px;
}

/* override the wysiwyg editor block styles */
.public-DraftStyleDefault-block,
.richEditor > * {
  margin: 0;
}
.richEditor {
  padding: 15px;
  min-height: 360px;
  cursor: text;
  border-radius: 5px;
  width: 100%;
}
.richToolbar {
  border: none;
}
.blog-portal .ant-input,
.richToolbar,
.richEditor {
  border: 1px solid #f0f0f0;
  border-radius: 6px;
}

@media only screen and (max-width: 600px) {
  .blog-portal {
    width: 85vw;
  }

  .createBlog-footer .blog-portal-nav {
    width: auto;
  }
}

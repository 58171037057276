.Blogs .Blog .Blog-Body p {
  font-size: 14px;
  color: black;
}

.blog-btn:hover {
  background: none !important;
}

.edit {
  color: #1890ff;
}

.archive {
  color: rgb(0, 128, 0);
}
.delete {
  color: rgb(255, 99, 71);
}
.edit:hover {
  color: #1890ff !important;
}

.archive:hover {
  color: rgb(0, 128, 0) !important;
}
.delete:hover {
  color: rgb(255, 99, 71) !important;
}
/* #16: Partial display fix */
/* .ReadBlog .Blog-Body {
  margin-left: 10px;
  z-index: 1;
} */
@media only screen and (max-width: 600px) {
}
